import { Component } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    public navController: NavController,
  ) {
    this.initializeApp()
  }
  initializeApp() {
    this.platform.ready().then(() => {
      let platformName = this.platform.platforms()[0]

      if (platformName == 'desktop') {
        localStorage.setItem('device_type', 'desktop')
      } else {
        localStorage.setItem('device_type', 'mobile')
      }

      console.log(`Platform ready on ${platformName}...`)
    });
  }
}
