import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'member-group',
    loadChildren: () => import('./pages/member-group/member-group.module').then(m => m.MemberGroupPageModule)
  },
  {
    path: 'message-detail',
    loadChildren: () => import('./pages/message-detail/message-detail.module').then(m => m.MessageDetailPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'message-send',
    loadChildren: () => import('./pages/message-send/message-send.module').then( m => m.MessageSendPageModule)
  },
  {
    path: 'member-receiver-form',
    loadChildren: () => import('./pages/member-receiver-form/member-receiver-form.module').then( m => m.MemberReceiverFormPageModule)
  },
  {
    path: 'message-detail-history',
    loadChildren: () => import('./pages/message-detail-history/message-detail-history.module').then( m => m.MessageDetailHistoryPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
